<template>
  <Page full>
    <grid
      type="labor_type"
      title="Labor rates"
      :multiple="true"
      searchMethod="filter"
      :order="[['company_id', 'desc']]"
      :isMain="true"
      :filters="filters"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'
import AutoCost from '../../../imports/api/AutoCost'

export default {
  mixins: [PageMixin],
  name: 'LaborTypes',
  computed: {
    hasLivePricingEnabled() {
      return AutoCost.hasAutoCostSubscription(this.$store.state.session.company)
    },
    filters() {
      return { labor_type_is_indexed: 0 }
    }
  },
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
